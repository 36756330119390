import React, { useEffect } from "react";
import "../styling/sitemap.css";
import NavBar from "../components/navigation-header";
import Footer from "../components/footer";

const IndexPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBar />

      <h1 className="sitemap-topic">Sitemap</h1>

      <div className="sitemap-container">
        <div className="sitemap-item">
          <h1>Pages</h1>
          <p>
            <a href="/">Home</a>
          </p>
          <p>
            <a href="/about/">About</a>
          </p>
          <p>
            <a href="/initiatives/">Initiatives</a>
          </p>
          <p>
            <a href="/products/">Products</a>
          </p>
          <p>
            <a href="/contact-us/">Contact</a>
          </p>
        </div>
        <div className="sitemap-item">
          <h1>Initiatives</h1>
          <p>
            <a href="/ocean-waste-management/">Ocean Waste Management</a>
          </p>
          <p>
            <a href="/pilgrimage-waste-management/">
              Pilgrimage Waste Management
            </a>
          </p>
          <p>
            <a href="/other-initiatives/">Other Initiatives</a>
          </p>
        </div>
        <div className="sitemap-item">
          <h1>Products</h1>
          <p>
            <a href="/product-yarn/">Yarn</a>
          </p>
          <p>
            <a href="/products-filament/">Monofilament</a>
          </p>
          <p>
            <a href="/pet-recycling/">Pet Recycling</a>
          </p>
        </div>
        <div className="sitemap-item">
          <h1>Legal</h1>
          <p>
            <a href="/cookies-policy/">Cookie Policy</a>
          </p>
          <p>
            <a href="/privacy-policy/">Privacy Policy</a>
          </p>
        </div>
      </div>

      <br />

      <Footer />
    </div>
  );
};

export default IndexPage;
